<template>
  <MosaicUiCoreSidebarMenuItem
    label="cabinet-hr.sidebar.dashboard"
    :route="`/cabinet/business/dashboard`"
    icon="mingcute:dashboard-3-line"
  />
  <MosaicUiCoreSidebarMenuItem
    label="cabinet-hr.sidebar.sessions"
    :route="`/cabinet/business/sessions`"
    icon="ic:round-list"
  />

</template>

<script setup lang="ts">
const { t } = useI18n();
</script>

<style scoped>

</style>
